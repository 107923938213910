import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { EmailTemplate } from 'app/types/email.template.types';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import EmailTemplateBodyEditor from './editors/EmailTemplateBodyEditor';
import { ISponsor } from 'app/types/sponsor.types';
import { constants } from 'common/helpers/Constants';
import { JSONContent } from '@tiptap/react';
import {
	useCreateEmailTemplateMutation,
	useSendPreviewEmailMutation,
	useUpdateEmailTemplateMutation,
} from 'api/redux/services/email.templates.service';
import EmailTemplateSubjectEditor from './editors/EmailTemplateSubjectEditor';
import { useAuth0 } from '@auth0/auth0-react';

interface ISaveEmailTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
	afterSave: () => void;
	emailTemplate?: EmailTemplate | null;
	sponsor: ISponsor;
	isPreview?: boolean;
}

export const SaveEmailTemplateModal: FC<ISaveEmailTemplateModalProps> = ({
	isOpen,
	onClose,
	afterSave,
	emailTemplate,
	sponsor,
	isPreview = false,
}) => {
	const sponsorTag = '{"type":"mention","attrs":{"id":"SPONSOR","label":null}}';
	const replacementSponsorTag = `{"type":"text","text":"${sponsor.name}"}`;

	const getSubject = () => {
		let subjectString = emailTemplate?.subject || '{}';
		if (
			isPreview &&
			!emailTemplate?.organizationId &&
			subjectString.includes(sponsorTag)
		) {
			subjectString = subjectString.replaceAll(
				sponsorTag,
				replacementSponsorTag,
			);
		}
		return JSON.parse(subjectString);
	};

	const getBody = () => {
		let bodyString = emailTemplate?.body || '{}';
		if (
			isPreview &&
			!emailTemplate?.organizationId &&
			bodyString.includes(sponsorTag)
		) {
			bodyString = bodyString.replaceAll(sponsorTag, replacementSponsorTag);
		}
		return JSON.parse(bodyString);
	};

	const [name, setName] = useState(emailTemplate?.name ?? '');
	const [subject, setSubject] = useState<JSONContent>(getSubject());
	const [body, setBody] = useState<JSONContent>(getBody());
	const [isSaving, setIsSaving] = useState(false);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [createEmailTemplate] = useCreateEmailTemplateMutation();
	const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
	const [sendPreviewEmail] = useSendPreviewEmailMutation();
	const { user } = useAuth0();

	const onSave = async () => {
		setIsSaving(true);
		await saveEmailTemplate();
		afterSave();
		setIsSaving(false);
		onClose();
	};

	const updateTemplate = async () => {
		if (!emailTemplate) return;

		await updateEmailTemplate({
			id: emailTemplate.id,
			name: name,
			subject: JSON.stringify(subject),
			body: JSON.stringify(body),
		});
	};

	const createTemplate = async () => {
		await createEmailTemplate({
			name: name,
			subject: JSON.stringify(subject),
			body: JSON.stringify(body),
			organizationId: sponsor.id,
		});
	};

	const saveEmailTemplate = async () => {
		if (emailTemplate?.id) {
			await updateTemplate();
			return;
		}

		await createTemplate();
	};

	const sendPreview = async () => {
		if (!emailTemplate || !user?.email) return;

		await sendPreviewEmail({
			emailTemplateId: emailTemplate.id,
			email: user.email,
			organizationId: sponsor.id,
		});
	};

	const onSendPreviewEmail = async () => {
		setIsSaving(true);
		await sendPreview();
		afterSave();
		setIsSaving(false);
		onClose();
	};

	const onBodyChange = (json: JSONContent) => {
		setBody(json);
	};

	const onSubjectChange = (json: JSONContent) => {
		setSubject(json);
	};

	const onCancel = () => {
		setName('');
		setSubject(JSON.parse('{}'));
		setBody(JSON.parse('{}'));
		onClose();
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>
				{isPreview ? 'Preview' : emailTemplate ? 'Save' : 'Create'} Template
			</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					label="Email Template Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={name}
					onChange={(e) => setName(e.target.value)}
					sx={{ marginBottom: 2 }}
					disabled={isPreview}
				/>
				<TextField
					label="Send From"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={sponsor.fromEmail || constants.SUPPORT_EMAIL}
					sx={{ marginBottom: 2 }}
					disabled
				/>
				<Box sx={{ marginTop: 3 }}>
					<Typography
						sx={{
							marginBottom: 1,
							marginLeft: 1,
							fontWeight: 'bold',
						}}
					>
						Subject
					</Typography>
					<Typography
						sx={{
							marginBottom: 1,
							marginLeft: 1,
						}}
					>
						{isPreview ? '' : 'Typing'} [FUND] or [INVESTOR] will display that
						fund and/or investor in the email subject.
					</Typography>

					<EmailTemplateSubjectEditor
						initialContent={subject || ''}
						style="border: 1px solid #BDBDBD; border-radius: 5px; padding-left: 5px;"
						onChange={onSubjectChange}
						isPreview={isPreview}
					/>
				</Box>
				<Box sx={{ marginTop: 5 }}>
					<Typography
						sx={{
							marginBottom: 1,
							marginLeft: 1,
							fontWeight: 'bold',
						}}
					>
						Body
					</Typography>
					<Typography
						sx={{
							marginBottom: 1,
							marginLeft: 1,
						}}
					>
						{isPreview ? '' : 'Typing'} [FUND], [INVESTOR], [LINK], or [USER]
						will display that fund, investor, document link, and/or name of user
						receiving the email.
					</Typography>
					<Box
						sx={{
							marginBottom: 2,
							border: '1px solid #BDBDBD',
							borderRadius: 1,
							padding: 1,
						}}
					>
						<EmailTemplateBodyEditor
							initialContent={body || ''}
							style="min-height: 150px;"
							onChange={onBodyChange}
							isPreview={isPreview}
						/>
					</Box>
				</Box>
			</Box>
			<DialogActions>
				<Button
					onClick={onCancel}
					sx={{ color: `${sponsorColor}`, textTransform: 'none' }}
				>
					Cancel
				</Button>
				{!isPreview ? (
					<Button
						variant="contained"
						onClick={onSave}
						disabled={isSaving || isPreview || !name || !subject || !body}
						sx={{ backgroundColor: `${sponsorColor}`, textTransform: 'none' }}
					>
						{isSaving ? 'Please wait...' : 'Save'}
					</Button>
				) : (
					<Button
						variant="contained"
						onClick={onSendPreviewEmail}
						disabled={isSaving}
						sx={{ backgroundColor: `${sponsorColor}`, textTransform: 'none' }}
					>
						Send Preview Email
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
