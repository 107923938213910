import { Box, Button, CircularProgress } from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { FC, useEffect, useState } from 'react';
import { useFileContext } from './FileContext';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onResetUploadState,
	onSetErrorMessage,
	onSetFundDialogOpen,
	onSetSuccessMessage,
} from 'api/redux/DocsUploadReducer';
import { prepareFileData } from './utils';
import {
	useNotifySponsorLevelUsersMutation,
	useUploadDocumentMutation,
	useUploadFundDocumentMutation,
} from 'api/redux/services/documentApi';
import { EDocumentLevels } from './types';
import { useLazyGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { formatDate } from 'modules/documents/utils';
import { styled } from '@mui/system';

const SubmitButton = styled(Button)({
	border: '1px solid #1976d2',
	color: '#1976d2',
	width: '10rem',
	'&:disabled': {
		backgroundColor: 'white',
		color: 'rgba(0, 0, 0, 0.26)',
	},
	'&:hover, &.Mui-focusVisible': {
		color: '#1976d2',
		border: '1px solid #1976d2',
	},
});

export const BulkUploadButton: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const { uploadedFiles, removeAllFiles } = useFileContext();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const {
		notifyInvestors,
		selectedDocType,
		selectedDocDate,
		selectedLevel,
		fundLevelConfirmed,
		notificationDelay,
		emailTemplateId,
	} = useSelector(docsUploadState);
	const [uploadDocument, { error: uploadError }] = useUploadDocumentMutation();
	const [uploadFundDocument, { error: uploadFundDocError }] =
		useUploadFundDocumentMutation();
	const [notifySponsorLevelUsers] = useNotifySponsorLevelUsersMutation();
	const [getInvestors, investors] = useLazyGetInvestorsQuery();
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentFund.id) getInvestors(currentFund.id);
	}, [currentFund.id]);

	useEffect(() => {
		if (uploadError || uploadFundDocError) {
			setIsUploading(false);
			dispatch(onSetErrorMessage("Couldn't create the document."));
		}
	}, [uploadError, uploadFundDocError]);

	useEffect(() => {
		if (fundLevelConfirmed) handleSubmitFundLevelDocs();
	}, [fundLevelConfirmed]);

	if (uploadedFiles.length === 0) return <></>;

	const openFundLevelDialog = (e: React.MouseEvent) => {
		e.preventDefault();

		dispatch(onSetFundDialogOpen(true));
	};

	const handleSubmitFundLevelDocs = async () => {
		if (uploadedFiles.length === 0 || !selectedDocDate) return;

		setIsUploading(true);

		const documentIds: number[] = [];

		await Promise.all(
			uploadedFiles.map(async (file) => {
				const formData = prepareFileData(
					file,
					currentFund.id.toString(),
					file.date ? formatDate(file.date) : selectedDocDate,
					notifyInvestors,
					EDocumentLevels.FUND_LEVEL,
					notificationDelay,
					emailTemplateId,
				);

				await uploadFundDocument({ formData })
					.unwrap()
					.then((response) => {
						documentIds.push(...response.map((d) => d.id));
					});

				dispatch(onSetSuccessMessage(true));
				removeAllFiles();
			}),
		);

		if (notifyInvestors) {
			notifySponsorLevelUsers({
				fundId: currentFund.id,
				numberOfDocs: uploadedFiles.length,
				documentType: selectedDocType?.name || '',
				documentPeriod: selectedDocDate,
				documentIds: documentIds,
				notificationDelayId: notificationDelay.id,
				documentLevelId: 1,
			});
		}

		removeAllFiles();
		setIsUploading(false);
		dispatch(onResetUploadState({}));
	};

	const handleSubmitInvestorLevelDocs = async (e: React.MouseEvent) => {
		if (
			uploadedFiles.length === 0 ||
			!investors.data ||
			!selectedDocDate ||
			!emailTemplateId
		)
			return;

		e.preventDefault();
		setIsUploading(true);

		const documentIds: number[] = [];

		await Promise.all(
			uploadedFiles.map(async (file) => {
				const formData = prepareFileData(
					file,
					currentFund.id.toString(),
					file.date ? formatDate(file.date) : selectedDocDate,
					notifyInvestors,
					EDocumentLevels.INVESTOR_LEVEL,
					notificationDelay,
					emailTemplateId,
				);

				await uploadDocument({ formData })
					.unwrap()
					.then((response) => {
						documentIds.push(response.id);
					});

				dispatch(onSetSuccessMessage(true));
				removeAllFiles();
			}),
		);

		if (notifyInvestors) {
			notifySponsorLevelUsers({
				fundId: currentFund.id,
				numberOfDocs: uploadedFiles.length,
				documentType: selectedDocType?.name || '',
				documentPeriod: selectedDocDate?.toString(),
				documentIds: documentIds,
				notificationDelayId: notificationDelay.id,
				documentLevelId: 2,
			});
		}

		removeAllFiles();
		setIsUploading(false);
		dispatch(onResetUploadState({}));
	};

	const disabled = () => {
		if (isUploading || uploadedFiles.length === 0) return true;

		if (
			selectedLevel.name === EDocumentLevels.INVESTOR_LEVEL &&
			uploadedFiles.some((file) => !file.investor || file.guessed)
		)
			return true;

		return false;
	};

	return (
		<PermissionsWrapper
			scopes={[
				SCOPES.canUploadDocument,
				SCOPES.canNotifySponsorUsersOnUploadedDocuments,
				SCOPES.canNotifyInvestorUsersOnUploadedDocuments,
			]}
		>
			<Box textAlign="center">
				<SubmitButton
					onClick={
						selectedLevel.name === EDocumentLevels.FUND_LEVEL
							? (e) => openFundLevelDialog(e)
							: (e) => handleSubmitInvestorLevelDocs(e)
					}
					variant="outlined"
					disabled={disabled()}
				>
					{isUploading ? <CircularProgress size={20} /> : 'Upload'}
				</SubmitButton>
			</Box>
		</PermissionsWrapper>
	);
};
